
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  App,
  Events,
  postEvent,
  patchEvent,
  deleteEvent,
} from "@/api/witheredHeath";
import Notify from "@/utils/notifications";

@Component
export default class EventDrawer extends Vue {
  @Prop() event!: Events;
  @Prop() apps!: Array<App>;
  @Prop() create!: boolean;
  @Prop() show!: boolean;

  loading = false;
  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    code: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    geo: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    "app-id": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    "master-column": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };

  createEvent() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        postEvent(this.event)
          .then(() => {
            this.$emit("newEvent");
            Notify.successful("Evento creado exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateEvent() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.event && this.event.id)
          patchEvent(this.event.id, this.event)
            .then(() => {
              Notify.successful("Evento actualizado exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.closeDrawer();
            });
      }
    });
  }

  dropEvent() {
    this.loading = true;
    if (this.event.id)
      deleteEvent(this.event.id)
        .then(() => {
          Notify.successful("Evento eliminado exitosamente.");
        })
        .catch(error => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.closeDrawer();
        });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  get title() {
    if (this.create) {
      return "Crear nuevo evento";
    } else {
      if (this.event.app && this.event.app.id) {
        this.event["app-id"] = this.event.app.id;
      }
      return "Actualizar evento";
    }
  }

  get showDrawer() {
    return this.show;
  }
}
